import { Injectable } from '@angular/core';
import {
  TaskItemStatus,
  TasksClient,
  TaskSummaryDto,
} from '@cloudmed/resolution-services-api-client';
import { TasksWorkerCompletedListState } from './tasks-worker-completed-list.state';
import { TasksWorkerListBaseEffect } from '../tasks-worker-list-base.effect';
import { GetTasksParams } from 'src/app/shared/models/tasks.model';
import { setSortByDirection } from 'src/app/shared/utils/global.utils';
import { convertToGetTasksParams } from 'src/app/shared/utils/tasks.utils';

@Injectable({
  providedIn: 'root',
})
export class TasksWorkerCompletedListEffect extends TasksWorkerListBaseEffect<GetTasksParams> {
  override readonly getMyTasksArgs: GetTasksParams = {
    status: [TaskItemStatus.Completed],
    sort: [setSortByDirection<TaskSummaryDto>('completedAt', 'desc')],
  };
  override readonly apiFunc = (params: GetTasksParams) =>
    this.tasksClient.getTasks(...convertToGetTasksParams(params));

  constructor(
    private readonly tasksClient: TasksClient,
    public override readonly state: TasksWorkerCompletedListState
  ) {
    super(state);
  }
}
