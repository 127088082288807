import {
  LimitedResultOfTaskSummaryDto,
  TaskSummaryDto,
} from '@cloudmed/resolution-services-api-client';
import { finalize, Observable } from 'rxjs';
import { TasksWorkerListBaseState } from '../state/tasks-worker-list-base.state';
import { loadCursorBasedApi } from 'src/app/shared/utils/global.utils';

export abstract class TasksWorkerListBaseEffect<
  P extends { continuationToken?: string },
> {
  protected abstract getMyTasksArgs: P;
  protected abstract apiFunc: (
    params: P
  ) => Observable<LimitedResultOfTaskSummaryDto>;
  constructor(protected readonly state: TasksWorkerListBaseState) {}

  loadTasks(): void {
    this.state.setLoadingState(true);
    const allItems: TaskSummaryDto[] = [];
    loadCursorBasedApi<LimitedResultOfTaskSummaryDto, P>(
      this.apiFunc,
      this.getMyTasksArgs
    )
      .pipe(
        finalize(() => {
          this.state.setDataState(allItems);
          this.state.setLoadingState(false);
        })
      )
      .subscribe((response: LimitedResultOfTaskSummaryDto) => {
        allItems.push(...(response.items || []));
      });
  }
}
