import { Injectable } from '@angular/core';
import { TasksWorkerTodoListState } from './tasks-worker-todo-list.state';
import {
  MyClient,
  TaskItemStatus,
  TaskSummaryDto,
} from '@cloudmed/resolution-services-api-client';
import { TasksWorkerListBaseEffect } from '../tasks-worker-list-base.effect';
import { GetMyTasksParams } from 'src/app/shared/models/tasks.model';
import { setSortByDirection } from 'src/app/shared/utils/global.utils';
import { convertToGetMyTasksParams } from 'src/app/shared/utils/tasks.utils';

@Injectable({
  providedIn: 'root',
})
export class TasksWorkerTodoListEffect extends TasksWorkerListBaseEffect<GetMyTasksParams> {
  override readonly getMyTasksArgs: GetMyTasksParams = {
    status: [TaskItemStatus.Pending],
    sort: [
      setSortByDirection<TaskSummaryDto>('assignedToId', 'desc'),
      setSortByDirection<TaskSummaryDto>('createdAt', 'desc'),
    ],
    includeUnassigned: true,
  };

  override readonly apiFunc = (params: GetMyTasksParams) =>
    this.myClient.getMyTasks(...convertToGetMyTasksParams(params));

  constructor(
    private readonly myClient: MyClient,
    public override readonly state: TasksWorkerTodoListState
  ) {
    super(state);
  }
}
